@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.website-tunnel-switcher {
	.react-tabs {
		&__tab-list {
			margin-bottom: 0;
			display: flex;
			align-items: flex-end;

			@include gridle_state(md) {
				width: fit-content;
			}
		}

		&__tab {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px 24px;
			height: 36px;
			width: 100%;
			box-sizing: border-box;
			background: var(--tab-active-color, $color-primary);
			color: white;
			border: none;
			box-shadow: 4px -4px 12px 0px #00000080 inset;

			&:first-of-type {
				border-radius: 8px 0 0 0;
			}

			&:last-of-type {
				border-radius: 0 8px 0 0;
			}

			&--selected {
				height: 40px;
				background: white;
				color: var(--color-black, $black);
				box-shadow: none;

				&:first-of-type,
				&:last-of-type {
					border-radius: 8px 8px 0 0;
				}
			}

			@include gridle_state(md) {
				width: auto;
			}
		}

		&__tab-panel {
			.sdp-search-form__form {
				@include gridle_state(md) {
					box-shadow: none;
				}
			}
		}
	}

	&--multiple {
		.sdp-search-form__form {
			border-top-left-radius: 0;
			border-top-right-radius: 0;

			@include gridle_state(md) {
				border-top-right-radius: 8px;
			}
		}
	}
}
